import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    labels: Array,
    interestMargin: Number,
    handlingFee: Number,
    expectedLoss: Number,
    intermediaryFee: Number,
    intermediaryFixedContribution: Number,
    miscellaneousFee: Number,
    profitMargin: Number
  }

  connect() {
    this.createChart()
  }

  disconnect() {
    this.element.querySelector('canvas').remove()
  }

  createChart() {
    const handlingFeeOffset = this.interestMarginValue
    const expectedLossOffset = handlingFeeOffset + this.handlingFeeValue - this.expectedLossValue
    const intermediaryFeeOffset = expectedLossOffset - this.intermediaryFeeValue
    const intermediaryFixedContributionOffset = intermediaryFeeOffset
    const miscellaneousFeeOffset = intermediaryFixedContributionOffset + this.intermediaryFixedContributionValue - this.miscellaneousFeeValue

    new Chart(
      $('<canvas/>')
        .height(300)
        .appendTo(this.element)[0],
      {
        type: 'bar',
        data: {
          labels: this.labelsValue,
          datasets: [
            {
              data: [
                0,
                handlingFeeOffset,
                expectedLossOffset,
                intermediaryFeeOffset,
                intermediaryFixedContributionOffset,
                miscellaneousFeeOffset,
                0
              ],
              backgroundColor: 'rgba(0, 0, 0, 0)'
            },
            {
              data: [
                this.interestMarginValue,
                this.handlingFeeValue,
                this.expectedLossValue,
                this.intermediaryFeeValue,
                this.intermediaryFixedContributionValue,
                this.miscellaneousFeeValue,
                this.profitMarginValue
              ],
              backgroundColor: ['#000000', '#F89A1C', '#F89A1C', '#F89A1C', '#F89A1C', '#F89A1C', '#000000']
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label: (item) => {
                return accounting.formatMoney(item.yLabel, '€', 2, '.', ',')
              }
            }
          },
          scales: {
            xAxes: [{ stacked: true, ticks: { autoSkip: false } }],
            yAxes: [{
              stacked: true, ticks: {
                callback: (value) => {
                  return accounting.formatMoney(value, '€', 2, '.', ',')
                }
              }
            }]
          }
        }
      }
    )
  }
}
